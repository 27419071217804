export default function initAwa(awa, appId, pageName, accessTokenExist) {
  if (!awa) {
    return;
  }
  const config = {
    instrumentationKey: 'bb40d85409d54fb68801dbc7cb072455-d540a63e-722d-498e-8889-61790d63c054-7516',
    useDefaultContentName: true,
    useShortNameForContentBlob: true,
    isLoggedIn: accessTokenExist,
    autoCapture: {
      pageView: true,
      lineage: true,
      click: false
    },
    coreData: {
      appId: appId,
      pageName: pageName
    }
  };
  awa.init(config);
}
